<template>
  <div>
    <div class="background"></div>
      <div class="container-admin-login">
        <div class="row justify-content-center">
          <div class="col-sm-6 col-md-5 col-lg-4 col-xl-3 align-self-center">
            <div class="card">
              <div class="card-header">
                <h1 class="text-center header-admin-login">Admin Login</h1>
              </div>
              <div class="card-body">
                <form @submit.prevent="login">
                  <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-user fa-2x"></i></div>
                    </div>
                    <input type="text" class="form-control" placeholder="Username" required v-model="username">
                  </div>
                  <div class="input-group mt-3">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-lock fa-2x"></i></div>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" required v-model="password">
                  </div>
                  <div class="text-center mt-4">
                    <button class="btn btn-outline-light" type="submit">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>  
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import authenticationService from '@/services/authentication';
import tools from '@/tools/cookie'; 
export default {
  data() {
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    async login() {
      const result = await authenticationService.adminLogin({
        userName: this.username,
        password: this.password
      });
      if(result.data) {
        tools.cookie.set('adminToken', result.data.token, 1);
        tools.cookie.set('studentToken', '', 0);
        if (this.username === 'Editor') this.$store.commit('setAuthenticationRole', 'Editor');
        else this.$store.commit('setAuthenticationRole', 'Admin');
        this.$router.push({name: 'AdminHome'});
      } else {
        this.username = '';
        this.password = '';
        this.$message.error(result.response.data);
      }
    }
  }
}
</script>
<style scoped>
.container-admin-login{
  padding: 0px 20px 0px 20px;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}

.row {
  height: 100%;
}

.card {
  background-color: rgba(0, 0, 0, 1);
  border: 2px solid #fff;
}

.card-header {
  border-bottom: none;
}

.input-group-text {
  background: transparent;
  color: #FFF;
  border: none;
}
.form-control {
  background-color: transparent;
  color: #FFF;
  border: none;
  border-bottom: #FFF 1px solid;
}

.form-control:focus {
  color: #FFF;
  background-color: transparent;
  border: none;
  box-shadow: unset;
  border-bottom: #80bdff 1.2px solid;
}

::placeholder {
  color: #b9b4b4;
  opacity: 1;
}

.header-admin-login {
  background-color: #fff;
  padding: 3px;
  margin-top: -43px;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 1px;
  color: black;
  font-family: cursive;
  padding-bottom: 10px;
}
</style>